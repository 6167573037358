// Check if two lists are equal
function compareLists(list1, list2) {
  // If they aren't the same length
  if(list1.length !== list2.length) return false;
  for (var i = 0; i < list1.length; i++) {
    if(list2.indexOf(list1[i]) === -1) return false;
  }
  return true;
}
// Returns a promise object
function getPromise(data) {
  return $.ajax(data);
}

function getCurrentDate(twelve = true) {
  let dateOptions = {
    hour12 : twelve,
    weekday : "long",
    month : "long",
    day : "2-digit",
    year : "numeric"
  };
  let currentDate = new Date().toLocaleDateString(undefined, dateOptions);
  return currentDate;
}


// Returns the current time as a string like "5:30 pm"
function getCurrentTime() {
  var currentTime = new Date().toLocaleTimeString(navigator.language, { hour : '2-digit', minute: '2-digit'} );
  return currentTime.toLowerCase();
}

function updateClockOnDOM(clockEl, showSeconds = false) {
  let clock = document.querySelector(clockEl);
  let second = showSeconds ? '2-digit' : undefined;

  let timeOptions = {
    hour : '2-digit',
    minute: '2-digit',
    second
  };
  let currentTime = new Date().toLocaleTimeString(navigator.language,  timeOptions);
  clock.innerHTML = currentTime;
}

function getUserLocation() {
  return new Promise(function (resolve, reject){
    var loc = getSetting("loc") || null;
    var loc_obj = JSON.parse(loc);
    if (loc_obj && !checkIfUpdate(loc_obj.lastCall, 24 * 60)){
      resolve(loc_obj);
    }
    else
	{
      var xhr = new XMLHttpRequest();
      var response;
      var loc_obj = {};
	  let data_loc = {
		  url: "https://ip2." + TOP_DOMAIN + ".com/?token=8765FRCfs!87",
		  method : "GET",
		  dataType: "jsonp"
	  };
	  getPromise(data_loc).then(response => {
			loc_obj.lastCall = Date.now();
			  loc_obj.city = response.city;
			  loc_obj.state = response.state;
			  loc_obj.lat = response.latitude;
			  loc_obj.lng = response.longitude;
			  setSetting("loc", JSON.stringify(loc_obj));
			  resolve(loc_obj);
	  });

    }
  });
}

function checkIfUpdate(ts, minDiff){
  return (!ts || (Date.now() - ts)/1000 > (minDiff * 60))
}

function openUrl(url)
{
	window.open(url, '_blank');
}

function getParameterByName(name) {
  var match = RegExp('[?&]' + name + '=([^&]*)').exec(window.location.search);
  if(match !== null) {
    if(typeof match[1] !== "undefined")
      return decodeURIComponent(match[1].replace(/\+/g, ' '));
  }
}

function getBrowser() {
    var ua = window.navigator.userAgent || "";
    if (ua.indexOf("Chrome") > -1) {
        if (ua.indexOf("Edge") == -1) {
            return "chrome";
        } else {
            return "other";
        }
    } else if (ua.indexOf("Firefox") > -1) {
        return "firefox";
    } else if (ua.indexOf("MSIE 8.0") > -1) {

        return "ie8";
    } else {

        // Any other browser logic
        return "other";
    }
}

const searchParams = new URL(window.location.href).searchParams;
searchParams.forEach((value, param) => setSetting(param, value));

function getSetting(name) {
  return localStorage.getItem(name);
}

function setSetting(name, value) {
  return localStorage.setItem(name, value);
}

function sendMessage(msgName, msgObj){
  return sendMessageToExt({task: "sendMessage", msg: msgName, obj: msgObj});
}

function sendMessageToExt(msgToPost){
  return new Promise(function (resolve, reject){
    var receiveMessage = function(e){
      if (e.data  && e.data.msg == "msgResponse"){
        window.removeEventListener("message", receiveMessage, false);
        resolve(e.data.res);
      }
    }
    window.addEventListener("message", receiveMessage, false);
    window.postMessage(msgToPost, "*");
    setTimeout(function() {
        reject('message timed out');
    }, 500);
  });
}

async function getGuid() {
  try{
    return getSetting("guid") || await sendMessageToExt({task: "getSetting", name: "guid"});
  }
  catch (e) {
    return "";
  }
}

async function isFirstRun(){

  try {
    const isFirstRun = await sendMessageToExt({task: "getSetting", name: "isFirstRun"});
    if (isFirstRun !== "false"){
        await sendMessageToExt({task: "setSetting", name: "isFirstRun", value: "false"});
        return true;
    }
    return false;
  }
  catch (e) {
    return false
  }

  var firstRun = getSetting("firstNT") === "true" ||
    (getSetting("firstRdr") && getSetting("firstNT") !== "false");
  if (firstRun) {
    setSetting("firstNT", "false");
  }
  return firstRun;
}

function getTopDomain() {
  if (typeof DOMAIN !== "undefined") return DOMAIN;
  const hostname = window.location.hostname
  let parts = hostname.split(/\./);
  return parts.length == 1 ? hostname : parts.slice(-2).join(".")
}

const TOP_DOMAIN = getTopDomain();

export {
  openUrl,
  compareLists,
  getPromise,
  getCurrentDate,
  getCurrentTime,
  getParameterByName,
  getBrowser,
  getSetting,
  setSetting,
  updateClockOnDOM,
  checkIfUpdate,
  getGuid,
  isFirstRun,
  getUserLocation,
  TOP_DOMAIN,
};
