const Util = require("./util.js");

class Shortcuts {
	getShortcutLinks() {
		let productId = Util.getSetting("ProductId") || window.location.pathname.split('/')[1];
		let url = `/shortcuts/${productId}/`;
		let data = {
			url: url,
			method : "GET"
		};
		return Util.getPromise(data);
	}
	async loadShortcuts(listID){
		try {
			let links = await this.getShortcutLinks();
			if (links) {
				links.forEach(function (item){
					$(listID).append(`<span><a href="${item.url}" class="tool-tip" data-tooltip-title="Go to ${item.text}" data-tooltip-position="bottom"><img src="${item.image}"></a></span>`);
				});
			}
		}
		catch(e) {
			console.log(e);
		}
	}
}

var shortcuts = new Shortcuts();
module.exports = shortcuts;
